#test-container {
    line-height: 1.8em;
    white-space: pre-wrap;
}

#test .answer {
    display: inline-block;
    padding: 0 5px;
    height: 1.6em;
    vertical-align: top;
}

#test .answer.input {
    width: 100px;
}

#test .answer.true {
    background-color: hsl(127, 100%, 95%);
    color: hsl(127, 100%, 40%);
}

#test .answer.false {
    background-color: hsl(0, 100%, 95%);
    color: hsl(0, 100%, 40%);
}

#test .answer.false span {
    text-decoration: line-through;
}