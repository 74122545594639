.container{
    max-width: 800px;
}

header {
    padding: 10px 0;
    background-color: #f5f5f5;
    box-shadow: 0 1px 5px #dddddd;
}

section h1 {
    text-align: center;
    padding: 10px;
    margin-bottom: 30px;
    background-color: white;
    box-shadow: 0 1px 5px #dddddd;
}

section button {
    display: block !important;
    margin: auto !important;
    white-space: pre !important;
    margin-top: 30px !important;
}

section h1 button {
    display: inline-block !important;
    margin: 0 -100px 0 100px !important;
}

section .line {
    min-height: 1em;
    box-sizing: content-box;
    padding: .2em .5em;
}