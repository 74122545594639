#text-container {
    line-height: 1.8em;
    white-space: pre-wrap;
}

#text .line {
    cursor: pointer;
    user-select: none;
}

#text .line.selected {
    background-color: hsla(0, 0%, 0%, 0.05);
}

#text .line.delimiter {
    outline: 2px solid hsla(0, 0%, 0%, 0.08);
}

#text .line:hover {
    background-color: hsla(0, 0%, 0%, 0.08);
}