#search-form {
    margin-bottom: 0;
}

#search-form .form-group {
    margin-bottom: 0;
}

#search-form .form-control {
    box-shadow: none !important;
}